import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme/index'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { ArrowRight } from 'react-feather'
import { Link as Scroll } from 'react-scroll'

export interface Props {
  arrow?: boolean
  label: string
  file?: string
  target?: string
  URL?: string
  onClick?: () => void
  className?: string
  noActions?: boolean
  rel?: string
  variant?: Variant
}

export const Button = memo(function Button({
  arrow,
  label = 'Button',
  file,
  target,
  URL,
  onClick,
  className,
  noActions,
  rel,
  variant = 'default',
}: Props) {
  const buttonClassName = `${className ? className : ''}`
  const buttonDOM = (
    <>
      {arrow ? <ArrowRight /> : null}
      {label}
    </>
  )
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return (
    <>
      {file ? (
        <Anchor
          className={buttonClassName}
          href={file}
          target="_blank"
          rel={rel}
          variant={variant}
        >
          {buttonDOM}
        </Anchor>
      ) : (
        <>
          {!externalURL ? (
            target === 'tel' || target === 'mailto' ? (
              <Anchor
                className={buttonClassName}
                href={`${target}:${URL}`}
                onClick={onClick}
                rel="noopener"
                variant={variant}
              >
                {buttonDOM}
              </Anchor>
            ) : onClick != undefined ? (
              <Static
                className={buttonClassName}
                onClick={onClick}
                variant={variant}
              >
                {buttonDOM}
              </Static>
            ) : target === 'scrollToForm' ? (
              <ScrollButton
                to="section-form"
                spy={true}
                smooth={true}
                offset={100}
                duration={500}
                variant={variant}
              >
                {arrow ? <ArrowRight /> : null}
                {label}
              </ScrollButton>
            ) : noActions ? (
              <Static className={buttonClassName} variant={variant}>
                {buttonDOM}
              </Static>
            ) : (
              <ButtonItem
                to={URL ? URL : '#'}
                className={buttonClassName}
                variant={variant}
              >
                {buttonDOM}
              </ButtonItem>
            )
          ) : (
            <Anchor
              className={buttonClassName}
              href={URL}
              rel="noopener"
              target={target}
              variant={variant}
            >
              {buttonDOM}
            </Anchor>
          )}
        </>
      )}
    </>
  )
})

const ButtonStyle = css`
  cursor: pointer;
  display: inline-flex;
  background: ${theme.colors.variants.primaryDark1};
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.125rem;
  margin-top: 3rem;
  padding: 1.3125rem 1.875rem;
  text-transform: uppercase;
  transition: 0.3s ease-out;
  &:hover {
    background: ${theme.colors.getHoverColor(
      theme.colors.variants.primaryDark1,
    )};
    svg {
      transform: translateX(7px);
    }
  }
  svg {
    height: 1.125rem;
    margin-right: 0.75rem;
    transition: 0.3s ease-out;
  }

  @media (max-width: 1023px) {
    margin-top: 2.25rem;
  }
`

const ButtonSimpleVariant = css`
  cursor: pointer;
  display: inline-block;
  color: ${theme.colors.variants.neutralDark4};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.125rem;
  margin-top: 3rem;
  position: relative;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &:after {
    content: '';
    display: block;
    height: 2px;
    background: ${theme.colors.variants.primaryDark1};
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    left: 0;
    transition: 0.3s ease-in-out;
  }
  &:hover {
    color: ${theme.colors.variants.primaryDark1};
    &:after {
      left: 100%;
    }
  }
  svg {
    display: none;
  }

  @media (max-width: 1023px) {
    margin-top: 2.25rem;
  }
`

const ButtonCornersStyle = css`
  cursor: pointer;
  display: inline-block;
  color: ${theme.colors.variants.neutralDark4};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.125rem;
  margin-top: 3rem;
  padding: 1.3125rem 1.875rem;
  position: relative;
  text-transform: uppercase;
  transition: 0.3s ease-out;
  &:before,
  &:after {
    content: '';
    width: 36px;
    height: 36px;
    position: absolute;
    transition: 0.3s ease-in-out;
  }
  &:before {
    border-top: 2px solid ${theme.colors.variants.primaryDark1};
    border-right: 2px solid ${theme.colors.variants.primaryDark1};
    top: 0;
    right: 0;
  }
  &:after {
    border-bottom: 2px solid ${theme.colors.variants.primaryDark1};
    border-left: 2px solid ${theme.colors.variants.primaryDark1};
    bottom: 0;
    left: 0;
  }
  &:hover {
    &:before,
    &:after {
      width: 100%;
      height: 100%;
    }
  }
  svg {
    display: none;
  }

  @media (max-width: 1023px) {
    margin-top: 2.25rem;
  }
`

const Anchor = styled.a<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return ButtonStyle
      case 'simple':
        return ButtonSimpleVariant
      case 'corners':
        return ButtonCornersStyle
    }
  }}
`

const Static = styled.div<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return ButtonStyle
      case 'simple':
        return ButtonSimpleVariant
      case 'corners':
        return ButtonCornersStyle
    }
  }}
`

const ButtonItem = styled(Link)<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return ButtonStyle
      case 'simple':
        return ButtonSimpleVariant
      case 'corners':
        return ButtonCornersStyle
    }
  }}
`

const ScrollButton = styled(Scroll)<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return ButtonStyle
      case 'simple':
        return ButtonSimpleVariant
      case 'corners':
        return ButtonCornersStyle
    }
  }}
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'simple' | 'corners'
